.impressum_font{
    font-size: 12px;
}

.impressum_position{
    margin-top: 100px;
    text-align: center;
}

.footer_impressum{
    position: relative;
    margin-top: 300px;
}