.font_accordion_first_element{
    margin-top: 15px;
    color: black;
}

.font_accordion{
    margin-top: 5px;
   /* color: floralwhite;*/
    color: black;
}


/*@media screen and (min-width: 550px) and (max-width: 1200px) {*/
/*    .font_accordion{*/
/*        font-size: 12px;*/
/*    }*/
/*}*/

/*@media screen and (min-width: 0px) and (max-width: 550px) {*/
/*    .font_accordion{*/
/*        font-size: 5px;*/
/*        color: white;*/
/*    }*/
/*}*/