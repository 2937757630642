.Praktikum-container {
    /*background: url('/images/LandwirtschaftPraktikum.jpg') center center/cover no-repeat;*/
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .Praktikum-btns {
   height: 650px;
  }

  .Praktikum-container > p {
    margin-top: 8px;
    height: 700px;
    color:#242424;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }