
.container_card_home{
    display:flex;
    justify-content: center;
    position: relative;
}

.box_bewerber_unternehmen{
    width: 500px;
    height: 550px;
    margin: 10% auto 15% auto;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 9px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
}


.cards_unternehmen_img{
/*    position: absolute;
    bottom: 0;*/
    border-radius: 10px;
    width: 100%;
    z-index: -1;
    height: 100%;
    max-height: 100%;
    object-fit: cover;

}

.cards_bewerber_img{
/*    position: relative;*/
    border-radius: 10px;
    width: 100%;
    z-index: -1;
    height: 100%;
    max-height: 100%;
    object-fit: cover;

}

.font_home_title{
    position: absolute;
    color: black;
    font-size: 22px;
    font-weight: bold;
    padding-top: 40px;
    padding-left: 20px;
}

.font_home_bewerber{
    position: absolute;
    color: black;
    margin-top: 20px;
    font-size: 16px;
    padding-top: 60px;
    padding-left: 20px;
    width: 450px;

}


.font_home_unternehmen{
    position: absolute;
    margin-top: 20px;
    color: black;
    font-size: 16px;
    padding-top: 60px;
    padding-left: 20px;
    width: 450px;

}

.bottom_home{
    position: absolute;
    color: black;
    font-size: 18px;
    padding-left: 20px;
    padding-top: 450px;
}

.bottom_home_style{
    background-color: rgb(255, 255, 255);
    box-shadow: 0 9px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: 3px 5px 3px 5px

}

.link_home{
    text-decoration: none;
    color: inherit;

}

@media only screen and (max-width: 1250px) {
    .box_bewerber_unternehmen {
        width: 400px;
        height: 450px;
    }
    .font_home_title{
        font-size: 20px;
    }
    .font_home_bewerber{
        font-size: 15px;
        width: 350px;
    }
    .font_home_unternehmen{
        font-size: 15px;
        width: 350px;
    }
    .bottom_home_style{
        margin-top: -75px;
        font-size: 15px;
    }
}

@media only screen and (max-width: 1150px) {
    .box_bewerber_unternehmen {
        width: 350px;
        height: 400px;
        margin: 10% auto 20% auto;
    }
    .font_home_title{
        font-size: 15px;
    }
    .font_home_bewerber{
        font-size: 12px;
    }
    .font_home_unternehmen{
        font-size: 12px;
    }
    .bottom_home_style{
        margin-top: -105px;
        font-size: 12px;
    }
}




    @media only screen and (max-width: 750px) {
    .container_card_home {
        display: flex;
        flex-flow: column;
        margin-bottom: 25%;
    }

    .box_bewerber_unternehmen{
        width: 350px;
        height: 400px;
        margin: 10% auto auto auto;
    }
    
    .font_home_title{
        font-size: 12px;
    }
    .font_home_bewerber{
        font-size: 10px;
    }
    .font_home_unternehmen{
        font-size: 12px;
    }

}

@media only screen and (max-width: 550px) {
    .container_card_home {
        display: flex;
        flex-flow: column;
        margin-bottom: 25%;
    }

    .box_bewerber_unternehmen{
        width: 350px;
        height: 400px;
        margin: 10% auto 5% auto;
    }
    .font_home_bewerber{
        font-size: 10px;
    }
    .font_home_unternehmen{
        font-size: 12px;
    }
    .bottom_home_style{
        margin-top: -120px;
        font-size: 12px;
    }
}


@media only screen and (max-width: 450px) {
    .container_card_home {
        display: flex;
        flex-flow: column;
        margin-bottom: 25%;
    }

    .box_bewerber_unternehmen{
        width: 250px;
        height: 300px;
        margin: 10% auto 5% auto;
    }
    .font_home_bewerber{
        font-size: 12px;
        width: 235px;
        margin-top: -20px;

    }
    .font_home_unternehmen{
        font-size: 12px;
        width: 235px;
        margin-top: -20px;
    }
    .bottom_home_style{
        margin-top: -200px;
        font-size: 12px;
    }
    .font_home_title{
        margin-top: -25px;
    }
}
