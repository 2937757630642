.navbarHeader{
    font-size: 22px;
    color: white;

}

.navbar-brand {
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
}

/* .bootstrapDrop{
 color: white;
 background-color:grey;
 background-size: 1px 1px;
 background-repeat: no-repeat;
 background-size: cover;
} */