
// .unternehmen-container {
//   background: url('../../assets/Unternehmenspatner.jpg') center center/cover no-repeat;
//   margin-top: 1%;
//   height: 100vh;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
//   object-fit: contain;
//   overflow-wrap: break-word;
// }

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.Parallax {
  display: flex;
  justify-content: center;

  &__background,
  &__background-triangles {
    width: 1080px;
    height: 1280px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
  }

  &__background {
    background-image: url("../../assets/Unternehmenspatner.jpg");
    width: 100%;
  }


  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40%;
    display: flex;
    flex-flow: column;
    width: 80%;
    height: 500px;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    height: 100%;

    &__heading,
    &__cta {
      text-align: center;
      display: flex;
      flex-direction: column;
    }

    &__heading {
      background-color: #fff;
      width: 100%;
      padding: 40px;

      &__text {
        margin: 10px 0;
        font-size: 60px;
      }

      &__caption {
        margin: 0;
        font-size: 30px;
      }
    }

    &__cta {
      background-color: #ff737e;
      width: 60%;
      padding: 40px;

      font-size: 20px;
      color: #fff;

      margin-top: 120px;
    }
  }
}

.boxes{
  margin: 20px;
  margin-top: 150px;
  background-color: rgb(198, 177, 198);
  overflow: hidden;
  height: 200px;
  max-height: 35vh;
  box-shadow: 0px 12px 18px -6px rgb(0, 0, 0.3);

}

.grid{
  max-width: 50%;
  margin: auto;

}


.break-font{
  overflow-wrap: break-word;
}


.column {
/*  display: flex;*/
  flex-direction: column;
  margin: auto;
  padding-left: 10%;
  padding-right: 10%;
}

.procedure_containter{
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.procedure_img{
  width: 100px;
  height: 100px;
  margin-bottom: 50px
}

.procedure{
  display: inline-block;
  margin-top: 50px;
  width: 80%;
  margin-left: 30px;
  font-size: 14px;

}

.accordion_containter {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  margin-bottom: 100px;
}


.accordion_backgroundcolor{
   background-color: gray;
    padding-top: 150px;
    padding-bottom: 50px;
    width: 100%;
}


.one {
  width: 35%;
  float: left;
  font-size: 14px;
}

.two {
  margin-left: 40%;
  font-size: 14px;
  width: 70%;
}

.contact_from {
  padding-top: 50px;
  margin-bottom: -250px;
}


.unternehmen-container {
  background: url('../../assets/BusinessPartner.jpg') center center/cover no-repeat;
  margin-top: 1%;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.container_process{
  font-size: 1px;
}


@media screen and (min-width: 850px) and (max-width: 1050px) {
  .procedure {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    width: 80%;
  }

  .procedure_img{
    width: 75px;
    height: 75px;
    margin-bottom: 50px;
    display: inline;
  }

  .procedure_containter{
    width: 80%;
  }
}




@media screen and (min-width: 500px) and (max-width: 860px) {
  .procedure {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    width: 450px;

  }

  .procedure_img{
    width: 75px;
    height: 75px;
    margin-bottom: 50px;
    display: inline;
  }

  .procedure_containter{
    margin-left: 10%;
    width: 600px;
  }

  .one {
    width: 45%;
    float: left;
    font-size: 14px;
    padding-bottom: 50px;
  }

  .two {
    margin-left: 40%;
    font-size: 14px;
    width: 70%;
  }

}


@media screen and (min-width: 0px) and (max-width: 760px) {
  .procedure {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    width: 80%;
  }

  .procedure_img{
   display: none;
  }

  .procedure_containter{
    width: 80%;
    margin-bottom: 100px;
    margin-left: 4%;
  }

  .one {
    float: none;
    font-size: 14px;
    display: inline;

  }

  .two {
    font-size: 14px;
    width: 100%;
    margin: 50px 0px 10px -11%;
  }
}