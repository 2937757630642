.container{

}

.head{
    padding-top: 10%;
    height: 100px;
    line-height: 20px;
    text-align: center;
}

.font{
    font-size: 15px;
    padding-block: 100px;
    text-align: center;
    padding: 8% 10% 0 10%;
}

.line {
    border: 1px solid darkorange;
    width: 90%;
    margin-top: 5%;
    position: absolute;
    left: 5%;
    right: 5%

}


.footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1.5rem;
}

.cards_inline{
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.cars_margin{
    margin-left: 10%;
}