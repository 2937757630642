.ferienbeschaeftigung-container {
    height: 100vh;
    width: 100%;

    object-fit: cover;

  }

  .ferienbeschaeftigung-btns {
   height: 650px;
  }

  .ferienbeschaeftigung-container > p {
    margin-top: 8px;
    height: 700px;
    color:#242424;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }