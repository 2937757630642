.cardsItem {
    margin-top: -550px;
    padding-left: 10rem;
    padding-right: 10rem;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 5000px;
    width: 100%;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
    
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    /* margin: 0 1rem; */
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    margin-top: -10em; 
    margin-bottom: -5em;
    background-color: white;
    z-index: 1;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  

  .cards__header__text {
    color: #2f2f2f;
    font-size: 60px;
    font-style: initial;
    font-weight: bold;
    line-height: 100px;

  }

  .cards__second__text {
    color: #2f2f2f;
    font-size: 25px;
    font-style: initial;
    line-height: 40px;
    margin-top: 24px;

  }

  .cards__third__text {
    color: #2f2f2f;
    font-size: 14px;
    font-style: initial;
    font-weight: lighter;
    line-height: 30px;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }

    .cards__third__text_b {
    color: #2f2f2f;
    font-size: 22px;
    font-style: bold;
    line-height: 30px;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .cards__info__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    font-weight: lighter;
    font-style: normal;
  }

    .cards__link__text {
    margin-left: 24px;
    margin-right: 24px;
    line-height: 30px;
  }

  .sectionBewerber{
    width: 100%;
  }


@media only screen and (min-width: 1200px) {
  ._blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  ._items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  ._item {
    margin-bottom: 2rem;
  }
}




@media only screen and (max-width: 1023px) {
  .cards__header__text  {
    font-size: 50px;
  }
  .cards__second__text{
    font-size: 18px;
  }
  .cards_textHeader{
    font-size: 18px;
  }
  .cards_text{
    font-size: 16px;
  }
  .cards__education{
    font-size: 16px;
  }
  .cards__education_header{
    font-size: 16px;
  }
}

@media only screen and (max-width: 650px) {
  .cards__header__text  {
    font-size: 30px;
  }
  .cards__second__text{
    font-size: 15px;
  }
  .cards_textHeader{
    font-size: 15px;
  }
  .cards__education_header{
    font-size: 15px;
  }

  .unternehmenspartner_cards{
    padding-left: 10px;
    padding-right: 10px;
  }
    .cards__container{
    width: 100%;
  }

}

  /* @media only screen and (max-width: 300px) {
    .cards__header__text  {
      font-size: 20px;
      word-break: break-all;
    }
  } */

/* @media only screen and (max-width: 1350px) {
  .cards__item__link {
    width: 100%;
    margin: 0px 0px 150px 0px;
  }
} */



@media only screen and (max-width: 650px) {
  .cards__item__link {
    width: 100%;
    margin: 0px 0px 150px 0px;
  }
}