  
  h1 {
    text-align: center;
  }
  
  .praktikum_cards__container {
    object-position: center;
    max-width: 5000px;
    width: auto;
    padding-right: 80px;
    padding-left: 40px;
    margin-top: -800px;

    
  }
  
  .praktikum_cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    background-color: white;
  }
  
  
  
  .praktikum_cards__header__text {
    color: #2f2f2f;
    font-size: 70px;
    font-style: initial;
    font-weight: bold;
    line-height: 100px;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 2%;

  }
  
  .praktikum_cards__header__Header {
    color: #2f2f2f;
    font-size: 30px;
    font-style: initial;
    font-weight: bold;
    line-height: 40px;
    padding-left: 10%;
    padding-right: 10%;
  
  }
  .praktikum_cards__header__HeaderHeader {
    color: #2f2f2f;
    font-size: 70px;
    font-style: initial;
    font-weight: bold;
    line-height: 100px;
    padding-left: 10%;
    padding-right: 10%;
  
  }
  
  .praktikum_cards__second__text {
    color: #2f2f2f;
    font-size: 22px;
    font-style: initial;
    line-height: 30px;
    margin-top: 24px;
    padding-left: 10%;
    padding-right: 10%;
  
  }
  
  .praktikum_cards__third__text {
    color: #2f2f2f;
    font-size: 22px;
    font-style: initial;
    font-weight: lighter;
    line-height: 30px;
    margin-top: 24px;
    padding-left: 3%;
    padding-right: 3%;
  
  }
  
  .praktikum_cards__third__text_b {
    color: #2f2f2f;
    font-size: 22px;
    font-style: bold;
    line-height: 30px;
    margin-top: 24px;
  
  }
  
  .praktikum_cards__info__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    font-weight: lighter;
    font-style: normal;
  }
  
  .praktikum_cards_text{
    margin-top: -20px;
  }
  
  .praktikum_cards_textHeader{
    font-size: 24px;
    font-weight: bold;
    text-decoration: underline black; 
    margin-top: 50px;
  }
  
  
  .praktikum_cards_textKursiv{
    margin-top: 0px;
    font-weight: bold;
    font-style: italic;
    font-size: 20px;
  }
  
  .praktikum_cards_textLink{
    margin-top: 100px;
    font-weight: bold;
    font-size: 24px;
  }
  
      
    

  @media only screen and (min-width: 1200px) {
    .praktikum_content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .praktikum_cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .praktikum_cards__item {
      margin-bottom: 2rem;
    }
  }
    
  
    .praktikum_cards_textKursiv{
      margin-top: 0px;
      font-weight: bold;
      font-style: italic;
      font-size: 20px;
    }
  
    .praktikum_cards_textLink{
      margin-top: 100px;
      font-weight: bold;
      font-size: 24px;
    }
  
    @media only screen and (min-width: 1200px) {
      .praktikum_content__blog__container {
        width: 84%;
      }
    }
    
    @media only screen and (min-width: 1024px) {
      .praktikum_cards__items {
        display: flex;
      }
    }
    
    @media only screen and (max-width: 1024px) {
      .praktikum_cards__item {
        margin-bottom: 2rem;
      }
    }
  
  
    @media only screen and (max-width: 1023px) {
      .praktikum_cards__header__text  {
        font-size: 50px;
      }
      .praktikum_cards__container {
          margin-top: -600px;
        }
      .praktikum_cards__second__text{
        font-size: 18px;
        }
      .praktikum_cards_textHeader{
        font-size: 18px;
      }
      .praktikum_cards_text{
        font-size: 16px;
      }
    }

    @media only screen and (max-width: 650px) {
      .praktikum_cards__header__text  {
        font-size: 30px;
      }
      .praktikum_cards__container {
          margin-top: -600px;
        }
      .praktikum_cards__second__text{
        font-size: 15px;
        }
      .praktikum_cards_textHeader{
        font-size: 15px;
      }
      .praktikum_cards_text{
        font-size: 15px;
      }
   
    }
  
  
  
  
    @media only screen and (max-width: 300px) {
      .praktikum_cards__header__H  {
        font-size: 20px;
        word-break: break-all;
      }
      .praktikum_cards__container {
          margin-top: -600px;
        }
    }

    