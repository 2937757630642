
.container_numbers{
  display: flex;
  margin: auto;
  padding-top: 40px;
}

.numbers{
  margin-top: -100px;
}


  .items_numbers_shadow {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 80px;
    overflow: hidden;
    text-decoration: none;
  }


.box {
    flex: 1; 
    margin: -30px auto 90px auto;
    width: 85%;
    display:flex; 
    /* flex-direction: row; */
    
    /* width: 800px;
    height:700px; */
    border: 5px rgb(255, 255, 255) solid;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 9px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;

}

.font-size-n{
    font-size: 12px;
    color: black; 
    padding: 0 120px 0 120px
}

.number-size-n{
    font-size: 80px;
    display: flex;
    padding: 0 120px 0 120px

}

.distance{
  margin: 0px 0px 0px -3%;
  display: flex;
}




@media only screen and (max-width: 1380px) {
  .font-size-n{
    font-size: 12px;
    color: black;
    padding: 0 100px 0 100px
}

.number-size-n{
    font-size: 80px;
    display: flex;
    padding: 0 100px 0 100px
  }
    .box{
        width: 86%
    }
}



@media only screen and (max-width: 1250px) {
  .font-size-n{
    font-size: 12px;
    color: black;
    padding: 0 80px 0 80px
}

.number-size-n{
    font-size: 80px;
    display: flex;
    padding: 0 80px 0 80px
  }
    .box{
        width: 82%
    }
}


@media only screen and (max-width: 1200px) {
  .box {
    width: 85%;
  }
}

@media only screen and (max-width: 1100px) {
  .font-size-n{
    font-size: 12px;
    color: black;
    padding: 0 50px 0 50px
}

.number-size-n{
    font-size: 80px;
    display: flex;
    padding: 0 50px 0 50px
  }

  .box {
    width: 86%;
  }
}

@media only screen and (max-width: 800px) {
  .font-size-n{
    font-size: 12px;
    color: black;
    padding: 0 30px 0 30px
}

.number-size-n{
    font-size: 80px;
    display: flex;
    padding: 0 30px 0 30px


  }
}

@media only screen and (max-width: 650px) {
  .font-size-n{
    font-size: 12px;
    color: black;
    padding: 0 10px 0 10px
}

.number-size-n{
    font-size: 80px;
    display: flex;
    padding: 0 10px 0 10px
  }
}

@media only screen and (max-width: 650px) {
    .font-size-n{
        font-size: 12px;
        color: black;
        padding: 0 10px 0 10px
    }

    .number-size-n{
        font-size: 80px;
        display: flex;
        padding: 0 10px 0 10px
    }
}

@media only screen and (max-width: 500px) {
    .font-size-n {
        font-size: 10px;
        color: black;
        padding: 0 10px 0 10px
    }

    .number-size-n {
        font-size: 45px;
        display: flex;
        padding: 0 10px 0 10px
    }
    .numbers{
        margin-top: -50px;
    }
    .box{
        width: 80%
    }

}

/*@media only screen and (max-width: 1380px) {*/
/*    .font-size-n{*/
/*        font-size: 12px;*/
/*        color: black;*/
/*        padding: 0 100px 0 100px*/
/*    }*/

/*    .number-size-n{*/
/*        font-size: 80px;*/
/*        display: flex;*/
/*        padding: 0 100px 0 100px*/
/*    }*/
/*    .box{*/
/*        width: 86%*/
/*    }*/
/*}*/



/*@media only screen and (max-width: 1250px) {*/
/*    .font-size-n{*/
/*        font-size: 12px;*/
/*        color: black;*/
/*        padding: 0 80px 0 80px*/
/*    }*/

/*    .number-size-n{*/
/*        font-size: 80px;*/
/*        display: flex;*/
/*        padding: 0 80px 0 80px*/
/*    }*/
/*    .box{*/
/*        width: 82%*/
/*    }*/
/*}*/


/*@media only screen and (max-width: 1200px) {*/
/*    .box {*/
/*        width: 85%;*/
/*    }*/
/*}*/

/*@media screen and (min-width: 1100px) and (max-width: 1200px)  {*/
/*    .font-size-n{*/
/*        font-size: 12px;*/
/*        color: black;*/
/*        padding: 0 50px 0 50px*/
/*    }*/

/*    .number-size-n{*/
/*        font-size: 80px;*/
/*        display: flex;*/
/*        padding: 0 50px 0 50px*/
/*    }*/

/*    .box {*/
/*        width: 86%;*/
/*    }*/
/*}*/

/*@media screen and (min-width: 800px) and (max-width: 1100px) {*/
/*    .font-size-n{*/
/*        font-size: 12px;*/
/*        color: black;*/
/*        padding: 0 30px 0 30px*/
/*    }*/

/*    .number-size-n{*/
/*        font-size: 80px;*/
/*        display: flex;*/
/*        padding: 0 30px 0 30px*/


/*    }*/
/*}*/



/*@media screen and (min-width: 501px) and (max-width: 800px){*/
/*    .font-size-n{*/
/*        font-size: 12px;*/
/*        color: black;*/
/*        padding: 0 10px 0 10px*/
/*    }*/

/*    .number-size-n{*/
/*        font-size: 80px;*/
/*        display: flex;*/
/*        padding: 0 10px 0 10px*/
/*    }*/
/*}*/


/*@media screen and (min-width: 376px) and (max-width: 500px){*/
/*    .font-size-n {*/
/*        font-size: 10px;*/
/*        color: black;*/
/*        padding: 0 10px 0 10px*/
/*    }*/
/*    .number-size-n {*/
/*        font-size: 10px;*/
/*        display: flex;*/
/*        padding: 0 10px 0 10px*/
/*    }*/
/*}*/
