
  .hero-container {
    background: url('../assets/UnsereGebiete.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container > h1 {
    color:#242424;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    color:#242424;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  /* .hero-btns {
    margin-top: 450px;
  } */
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
 
  }

  @media screen and (max-width: 1200px) {
    .hero-container > h1 {
      font-size: 70px;
      width: 100%;
    }

  }
  
  @media screen and (max-width: 1000px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
      width: 100%;
    }

    .hero-btns .btn {
      height: 60px;
      width: 100%;
      font-size: 18px;
    }
  }




  @media screen and (min-width: 0px) and (max-width: 320px){
    .hero-container > h1 {
      margin-top: 0px;
    font-size: 30px;
    width: 100%;
  }
  .hero-container > p {
    color:#242424;
    font-size: 16px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
    .btn {
      width: 100%;
    }
    .hero-btns .btn {
      height: 40px;
      font-size: 12px;
    }
}




  @media screen and (min-width: 321px) and (max-width: 375px){
    .hero-container > h1 {
      margin-top: 0px;
      font-size: 40px;
      width: 100%;
    }
    .hero-container > p {
      color:#242424;
      font-size: 22px;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
    .btn {
      width: 100%;
    }
    .hero-btns .btn {
      height: 50px;
      font-size: 15px;
    }
  }



  @media screen and (min-width: 376px) and (max-width: 767px){
    .hero-container{
      width: 100%;
    }

    .hero-container > h1{
      width: 100%;
      font-size: 40px;
      word-break: break-word;
      text-align: center;
      padding-left: 5%;
      padding-right: 5%;
    }
  
    .hero-container > p {
      font-size: 20px;
      word-break: break-word;
      text-align: center;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
