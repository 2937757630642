.cards {
    padding: 4rem;
    background: #fff;
}

h1 {
    text-align: center;
}

/*.cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 2500px;
    width: 100%;
    margin: 0 auto;
}*/

/*.text_header_cards{
    color: #2f2f2f;
    font-size: 30px;
    font-style: initial;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 40px;
}*/

/*.cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
}*/

.cards_personal_items {
    margin-bottom: 20px;
}

.cards_personal_item {
    display: flex;
    width: 300px;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
}

.cards_personal_item_link {
    display: flex;
    flex-flow: column;
    width: 200px;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.cards_personal_item_pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
}


.cards_personal_item_pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 40px);
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    /* background-color: #D38344; */
    box-sizing: border-box;
    word-break: normal;
}

.cards_personal_item_img{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}



.cards__item__info {
    padding: 20px 30px 30px;
}

.cards_personal_item_text {
    margin-left: 5%;
}

.cards_personal_text_margin{
    margin-bottom: 100px;
}


@media only screen and (min-width: 1150px) {
    .content__blog__container {
        width: 84%;
    }
}

/*@media only screen and (min-width: 950px) {
    .cards_personal_items {
        display: flex;
        margin: -50px 45px 100px 0;
    }
}*/


/* @media only screen and (min-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
} */


/* @media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
    width: auto;
  }
} */

/*

@media only screen and (max-width: 1400px) {
    .cards_personal_items {
        margin-bottom: 2rem;
        width: 300px;
        display: flex;
    }
}
*/


