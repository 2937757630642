.bewerber-container {
    background-image: url("../../assets/Bewerber_Student.jpg");
    /* background-image: url("../../assets/FerienjobE.mp4"); */
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  }

  .bewerber-btns {
    height: 600px;
  }

  .bewerber-container > p {
    margin-top: 8px;
    height: 700px;
    color:#242424;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

.section1 {
  background-color: gray;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 100px;
}

.section1::before {
  content: "";
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  height: 100px;
  background-color: white;
  border-radius: 75%;
  width: 100%;
}

.section1::after {
  content: "";
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  height: 100px;
  background-color: white;
  border-radius: 75%;
}

  .section2{
      width: 100%;
      height: 500px;
      top: 0;
      z-index: -9;
      /* display: flex; */
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background-color: transparent;
  }

.section3{
    width: 100vw;
    overflow: hidden;
    position: relative;
    background-color: gray;
}

.section3::before {
  content: "";
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  height: 100px;
  background-color: white;
  border-radius: 75%;
}

.section3::after {
  content: "";
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  height: 100px;
  background-color: white;
  border-radius: 75%;
}

.section4{
    width: 100vw;
    max-width: 100%;
    height: 100%;
    position: relative;
    /*top: 0; */
    z-index: -9;
    overflow: hidden;
    margin-top: 10px;
    background-color: transparent;
}



  .section_container{
      margin-top: 50px;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
  }

.white-header{
    color: white;
    margin-top: 100px
}

.white-text{
    justify-content: center;
    /* text-align: center; */
    padding-left: 100px;
    margin: 40px auto auto auto;
    color: white;
    margin-right: 10%;
    font-size: 15px;

}

.white-short-header{
    padding-left: 50px;
    padding-right: 50px;
    justify-content: center;
    text-align: center;
    margin: auto;
    color: white;
    font-size: 24px;

}

.black-header{
    color: black;
}

.black-text{
    justify-content: center;
    margin-right: 10%;
    /*text-align: center;*/
    padding-left: 100px;
    margin-top: 40px;
    color: black;
    font-size: 15px;
}

.black-short-header{
    padding-left: 50px;
    padding-right: 50px;
    justify-content: center;
    text-align: center;
    margin: auto;
    color: black;
    font-size: 24px;
}


.float-child {
    width: 50%;
    float: left;
    padding: 20px;
    margin-bottom: 20px;
}

.bottom-text{
    margin-top: 50px;
    float: none;
}

.footer-bewerber{
    clear: left;
    margin-top: -300px;
}





    @media only screen and (max-width: 750px) {
    .white-header{
      font-size: 22px;
    }
    .white-short-header{
      font-size: 15px;
    }
    .white-text{
      font-size: 12px;
    }
    .white-header2{
      font-size: 15px;
    }
    .black-text{
    font-size: 12px;
    }
    .black-header{
      font-size: 22px;
    }
    .black-short-header{
      font-size: 15px;
    }
    .black-text{
      font-size: 12px;
    }
    .black-header2{
      font-size: 15px;
    }

}


@media only screen and (max-width: 450px) {
   .float-child {
    width: 100%;
    margin-bottom: 20px;
    margin-left: -50px;
}

.section1::before {
  height: 0px;
}

.section1{
  height: 100%;

}
.section1::after{
  height: 0px;
}

.section2{
  height: 100%;
  margin-top: 100px;
}

.section3{
}

.section3::before {
  height: 0px;
}

.section3::after {
height: 0px;
}

.section4{
  margin-top: 100px;
}
}
