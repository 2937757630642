.unternehmenspartner_cards {
    margin-top: 25px;
    padding-left: 10rem;
    padding-right: 10rem;
  }
  
  h1 {
    text-align: center;
  }
  
  .unternehmens_cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 5000px;
    width: 100%;
    margin: 0 auto;
    
  }
  
  .unternehmens_cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
    
  }
  
  .unternehmens_cards__items {
    margin-bottom: 24px;
    
  }
  
  .unternehmens_cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    
   
  }
  
  .unternehmens_cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    margin-top: -10em; 
    margin-bottom: -5em;
    background-color: white;
  }
  
  .unternehmens_cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .unternehmens_cards__item__img:hover {
    transform: scale(1.1);
  }
  

  .unternehmens_cards__header__text {
    color: #2f2f2f;
    font-size: 60px;
    font-style: initial;
    font-weight: bold;
    line-height: 100px;

  }

  .unternehmens_cards__second__text {
    color: #2f2f2f;
    font-size: 25px;
    font-style: initial;
    line-height: 40px;
    margin-top: 24px;

  }

  .unternehmens_cards__third__text {
    color: #2f2f2f;
    font-size: 14px;
    font-style: initial;
    font-weight: lighter;
    line-height: 30px;
    margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px;


  }

  .unternehmens_cards__info__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
    font-weight: lighter;
    font-style: normal;
  }



@media only screen and (min-width: 1024px) {
  .unternehmens__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .unternehmens__item {
    margin-bottom: 2rem;
  }
}




@media only screen and (max-width: 1023px) {
  .unternehmens_cards__header__text  {
    font-size: 50px;
  }
  .unternehmens_cards__second__text{
    font-size: 18px;
  }
  .unternehmens_cards_textHeader{
    font-size: 18px;
  }
  .unternehmens_cards_text{
    font-size: 16px;
  }
  .unternehmens_cards__education{
    font-size: 16px;
  }
  .unternehmens_cards__education_header{
    font-size: 16px;
  }
}

@media only screen and (max-width: 650px) {
  .unternehmens_cards__header__text  {
    font-size: 30px;
  }
  .unternehmens_cards__second__text{
    font-size: 15px;
  }
  .unternehmens_cards_textHeader{
    font-size: 15px;
  }
  .unternehmens_cards__education_header{
    font-size: 15px;
  }

  .unternehmenspartner_cards{
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 300px) {
  .unternehmens_cards__header__text  {
    font-size: 20px;
    word-break: break-all;
  }

}

/* @media only screen and (max-width: 1350px) {
  .UnternehmensCards {
    unternehmens_cards__item__link: 100%;
    margin: 0px 0px 150px 0px;
  }
} */

